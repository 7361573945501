import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"
import BackgroundImage from 'gatsby-background-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSmile } from '@fortawesome/free-regular-svg-icons'
import "./blog-post.css"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { next } = data
  const featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid
  /* const brandColor = post.frontmatter.brandColor */

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
        <div id="wrap" class="project">
        <BackgroundImage
            Tag="section"
            className="projectHero"
            fluid={featuredImgFluid}
          >
            <div id="overlay">
            <header>
              <Nav></Nav>
              <section>
                  <h1>{post.frontmatter.title}</h1>  
              </section>
            </header>
            </div>
            </BackgroundImage>
        </div>
        <div id="wrap" class="main">
          <article
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <summary class="signature"><FontAwesomeIcon icon={faSmile} /></summary>
        </div>
      <div id="wrap" class="home">
        <header>
          <section>
            <h4>Next Project</h4>
            <h1>
              {next && (
                <Link to={next.fields.slug} id="cta" rel="next">
                  {next.frontmatter.title} <span>→</span>
                </Link>
              )}
            </h1>  
          </section>
        </header>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        brandColor
        date(formatString: "MMMM DD, YYYY")
        ref
        description
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
              
            }
          }
        }
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
